.policy {
  ol, ul {
    list-style: decimal;
  }
  
  ol ol {
    margin-left: 1rem;
  }
  
  li p:first-child, li::marker {
    margin: 1rem auto;
    font-weight: 600;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}
