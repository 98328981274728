@tailwind base;

@tailwind components;

@tailwind utilities;

html,
body {
  background: rgb(219 234 254);
}

.kommen-sie-mit {
  margin-bottom:auto;
  animation: kommenSieMit 1s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -1s);
}
@keyframes kommenSieMit {
  0% {
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
  }

  3% {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
  }

  100% {
    visibility: hidden;
  }
}

.car {
  height: 20%;
  background-size: contain;
  bottom: 0;
  left: 0;
  background-position: bottom;
  transform-origin: bottom;
}


.vector1 { animation: vector1 100s ease-in-out infinite; }
.vector2 { animation: vector2 100s ease-in-out infinite; }
.vector3 { animation: vector3 100s ease-in-out infinite; }
.vector4 { animation: vector4 100s ease-in-out infinite; }
.vector5 { animation: vector5 100s ease-in-out infinite; }
.vector6 { animation: vector6 100s ease-in-out infinite; }
.vector7 { animation: vector7 100s ease-in-out infinite; }
.vector8 {
  animation: vector8 50s ease-in-out infinite; 
  height: 40%; 
}
.car {
  animation: car 25s ease-in-out infinite;
}

@media screen and (max-width:1024px) {
  .vector1 { animation: vector1-mobile 50s ease-in-out infinite; }
  .vector2 { animation: vector2-mobile 50s ease-in-out infinite; }
  .vector3 { animation: vector3-mobile 50s ease-in-out infinite; }
  .vector4 { animation: vector4-mobile 50s ease-in-out infinite; }
  .vector5 { animation: vector5-mobile 50s ease-in-out infinite; }
  .vector6 { animation: vector6-mobile 50s ease-in-out infinite; }
  .vector7 { animation: vector7-mobile 50s ease-in-out infinite; }
  .vector8 { 
    animation: vector8-mobile 50s ease-in-out infinite; 
    height: 40%; 
  }
  .car {
    animation: car-mobile 25s linear infinite;
    transform: scale(0.5);
  }
}

.vector8 {
  transform-origin: bottom;

  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover !important;
}

.vector-bg {
  background-size: 140%;
  transition-timing-function: ease;
}

@keyframes vector1 {
  0% { transform: translate(0, 20%); } 
  50% { transform: translate(0, 10%); }
  100% { transform: translate(0, 20%); }
}

@keyframes vector2 {
  0% { transform: translate(0, 40%); } 
  50% { transform: translate(0, 20%); }
  100% { transform: translate(0, 40%); }
}
@keyframes vector3 {
  0% { transform: translate(0, 40%); } 
  50% { transform: translate(0, 10%); }
  100% { transform: translate(0, 40%); }
}

@keyframes vector4 {
  0% { transform: translate(0, 70%); } 
  50% { transform: translate(0, 55%); }
  100% { transform: translate(0, 70%); }
}

/* dark turquoise */
@keyframes vector6 {
  0% { transform: translate(0, 60%); } 
  50% { transform: translate(0, 75%); }
  100% { transform: translate(0, 60%); }
}

/* yellow */
@keyframes vector7 {
  0% { transform: translate(0, 65%); } 
  50% { transform: translate(0, 70%); }
  100% { transform: translate(0, 65%); }
}

@keyframes vector8 {
  0% { transform: scale(1, 0.8); } 
  50% { transform: scale(1, 0.6); } 
  100% { transform: scale(1, 0.8); }
}
@keyframes car { 
  0% { transform: translate(0, -20%) scale(1); } 
  50% { transform: translate(0, -30%) scale(1.1); } 
  100% { transform: translate(0, -20%) scale(1) } 
}


/* Mobile */

@keyframes vector1-mobile {
  0% { transform: translate(0, 40%); } 
  50% { transform: translate(0, 30%); }
  100% { transform: translate(0, 40%); }
}

@keyframes vector2-mobile {
  0% { transform: translate(0, 40%); } 
  50% { transform: translate(0, 30%); }
  100% { transform: translate(0, 40%); }
}
@keyframes vector3-mobile {
  0% { transform: translate(0, 40%); } 
  50% { transform: translate(0, 30%); }
  100% { transform: translate(0, 40%); }
}

@keyframes vector4-mobile {
  0% { transform: translate(0, 60%); } 
  50% { transform: translate(0, 45%); }
  100% { transform: translate(0, 60%); }
}

/* dark turquoise */
@keyframes vector6-mobile {
  0% { transform: translate(0, 55%); } 
  50% { transform: translate(0, 70%); }
  100% { transform: translate(0, 55%); }
}

/* yellow */
@keyframes vector7-mobile {
  0% { transform: translate(0, 60%); } 
  50% { transform: translate(0, 70%); }
  100% { transform: translate(0, 60%); }
}

@keyframes vector8-mobile {
  0% { transform: scale(1, 0.9); } 
  50% { transform: scale(1, 0.8); } 
  100% { transform: scale(1, 0.9); }
}

@keyframes car-mobile { 
  0% { transform: translate(0, -65%) scale(0.7); } 
  50% { transform: translate(0, -70%) scale(0.7); } 
  100% { transform: translate(0, -65%) scale(0.7); } 
}

@font-face {
  font-family: "Gili";
  src: local("gili"), url("./fonts/gili.ttf") format("truetype");
  //font-weight: bold;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.detail strong {
  margin-top: 2rem;
  display: block;
  font-size: 1.5rem;
}

.services ul {
  list-style-type: none;
}

.services ul li {
  margin-bottom: 1rem;
  padding-left: 7px;
}

.services ul li::marker {
  content: "✓";
  font-weight: bold;
  font-size: 1.6rem;
}

.public-DraftEditor-content {
  height: 200px !important;
  overflow-wrap: anywhere !important;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}