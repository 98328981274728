:root{
    --color: #FFF;
}

@keyframes action {
    from { transform: translateY(0); }
    to { transform: translateY(-10px); }
}
@-webkit-keyframes action {
    from { transform: translateY(0); }
    to { transform: translateY(-10px); }
}

.triangle-container{
    animation: kommenSieMit 1s linear infinite;
    color: var(--color);
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
}


.triangle_down1 {
    position: relative;
    top: -25px;
    content: "";
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    border-right: 0.2em solid var(--color);
    border-top: 0.2em solid var(--color);
    transform: rotate(135deg);
    margin-right: 0.5em;
    margin-left: 1.0em;
    margin-top:3rem;
}

